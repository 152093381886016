import { useEffect } from 'react'

// import React, { useEffect, useState } from 'react'
// import styled from 'styled-components'
// import MediaQuery from '@monorepo-amais/commons/components/mediaQuery'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'

// import { BreadcrumbAuto, MainContainer } from '../Blocks'

// import theme from '../../theme'

// import SEO from '../seo'

// import Accordion from '../Accordion'

// import Banner from './banner'

// import { AccordionsContainer, AccordionGrid, GridContainer } from './styled'

// import Select from 'react-select'
// import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

// const AboutText = styled.div`
// 	font-size: 16px;
// 	font-weight: normal;
// 	font-style: normal;
// 	font-stretch: normal;
// 	line-height: 1.5;
// 	letter-spacing: normal;
// 	text-align: left;
// 	color: ${theme.colors.gray66};
// 	margin: 0px;
// 	padding: 0 10px;
// `

// const selectStyles = {
// 	indicatorSeparator: base => ({
// 		...base,
// 		background: 'none'
// 	}),
// 	dropdownIndicator: base => ({
// 		...base,
// 		svg: {
// 			fill: theme.colors.primary
// 		}
// 	}),
// 	control: base => ({
// 		...base,
// 		borderBotton: '1px',
// 		borderTop: '0px',
// 		borderLeft: '0px',
// 		borderRight: '0px',
// 		borderRadius: '0px',
// 		backgroundColor: theme.colors.white,
// 		borderColor: theme.colors.graye2,
// 		boxShadow: 'none',
// 		'&:hover': {
// 			borderColor: theme.colors.graye2
// 		},
// 		width: '50%',
// 		'@media only screen and (max-width: 425px)': {
// 			width: '100%'
// 		}
// 	}),
// 	menu: base => ({
// 		...base,
// 		marginTop: 0,
// 		textAlign: 'left',
// 		wordWrap: 'break-word',
// 		overflow: 'hidden',
// 		width: '50%',
// 		'@media only screen and (max-width: 425px)': {
// 			width: '100%'
// 		}
// 	}),
// 	menuList: base => ({
// 		...base,
// 		padding: 0
// 	}),
// 	singleValue: base => ({
// 		...base,
// 		color: theme.colors.gray66,
// 		fontSize: '16px',
// 		lineHeight: '17px',
// 		left: '0px'
// 	}),
// 	placeholder: base => ({
// 		...base,
// 		color: theme.colors.gray66,
// 		fontSize: '16px',
// 		lineHeight: '17px',
// 		left: '0px'
// 	}),
// 	option: (base, state) => ({
// 		...base,
// 		fontSize: '16px',
// 		lineHeight: '17px',
// 		backgroundColor: state.isSelected ? theme.colors.primary : theme.colors.white,
// 		color: state.isSelected ? theme.colors.white : theme.colors.gray66,
// 		':active': {
// 			backgroundColor: state.isSelected ? theme.colors.primary : theme.colors.white,
// 			color: state.isSelected ? theme.colors.white : theme.colors.gray66
// 		},
// 		'&:hover': {
// 			backgroundColor: theme.colors.primary,
// 			color: theme.colors.white
// 		}
// 	})
// }

// const ButtonLink = styled.a`
// 	display: block;
// 	width: fit-content;
// 	padding: 0.5rem 1rem;
// 	margin: 24px 0 48px 0;
// 	font-size: 16px;
// 	background-color: ${props => (props.selected ? props.theme.colors.primary : props.theme.colors.graye2)};
// 	color: ${theme.colors.white};
// 	border: none;
// 	border-radius: 100px;
// 	cursor: ${props => (props.selected ? 'pointer' : 'default')};
// 	text-decoration: none;

// 	white-space: pre-wrap;

// 	&:not([disabled]):hover {
// 		background: ${props => (props.selected ? props.theme.colors.primary_hover : props.theme.colors.graye2)};
// 	}

// 	&:not([disabled]):active {
// 		background: ${props => (props.selected ? props.theme.colors.primary_hover : props.theme.colors.primary)};
// 	}
// `

const PrivacidadeComponent = ({ Layout, cosmicjsInformacoesEstaticas }) => {
	useEffect(() => {
		window.location.replace('https://privacyportal.onetrust.com/policies/26edb1e8-78ab-465b-9129-d0d9cf1f833e?context=eyJkc1BvcnRhbElkIjoiMGFjZGE4ODgtNDNlZS00OTdjLTk4MmQtMTExMmE2Y2ZhM2NkIn0%3D')
	}, [])

	return null

	// const [language, setLanguage] = useState('br')

	// const changeLanguage = lang => {
	// 	setLanguage(lang)
	// 	setSelected({ label: '', url: '' })
	// }
	// const [expanded, setExpanded] = useState(false)

	// const { metadata } = cosmicjsInformacoesEstaticas
	// const [selected, setSelected] = useState({ label: '', url: '' })

	// const optionsArray = cosmicjsInformacoesEstaticas.metadata.section_channels.select_options.map(a =>
	// 	Object.assign({ ...a }, { value: a.label })
	// )

	// const optionsArrayEnglish = cosmicjsInformacoesEstaticas.metadata.section_channels.select_options.map(b =>
	// 	Object.assign({ ...b }, { value: b.label_ingles, label: b.label_ingles, url: b.url_ingles })
	// )

	// const handleChange = panel => (event, newExpanded) => {
	// 	setExpanded(newExpanded ? panel : false)
	// }

	// return (
	// 	<Layout>
	// 		<SEO
	// 			title={cosmicjsInformacoesEstaticas.metadata.banner_destaque.titulo}
	// 			description={cosmicjsInformacoesEstaticas.metadata.banner_destaque.descricao}
	// 			image={imgixUrlOptimizerAuto(cosmicjsInformacoesEstaticas.metadata.banner_destaque.imagem_destaque.imgix_url)}
	// 		/>

	// 		<MainContainer>
	// 			<MediaQuery minDeviceWidth={1224}>
	// 				{language === 'br' ? (
	// 					<BreadcrumbAuto title={cosmicjsInformacoesEstaticas.metadata.banner_destaque.titulo} />
	// 				) : (
	// 					<BreadcrumbAuto title={cosmicjsInformacoesEstaticas.metadata.banner_destaque.titulo_ingles} />
	// 				)}
	// 			</MediaQuery>
	// 			<Banner {...metadata.banner_destaque} language={language} setLanguage={changeLanguage} />
	// 		</MainContainer>
	// 		<AccordionsContainer>
	// 			<AccordionGrid>
	// 				<AboutText>
	// 					{cosmicjsInformacoesEstaticas.metadata.faq.map(
	// 						({ question, question_ingles, answer, answer_ingles }, index) => {
	// 							return (
	// 								<Accordion
	// 									bgWhite
	// 									key={index}
	// 									slug={index.toString()}
	// 									title={language === 'br' ? `${index + 1}. ${question}` : `${index + 1}. ${question_ingles}`}
	// 									expanded={expanded}
	// 									handleChange={handleChange}
	// 								>
	// 									<p>{language === 'br' ? answer : answer_ingles}</p>
	// 								</Accordion>
	// 							)
	// 						}
	// 					)}
	// 				</AboutText>

	// 				<AboutText>
	// 					<GridContainer className='channels'>
	// 						<p>
	// 							<span className='channels-title'>
	// 								{language === 'br'
	// 									? cosmicjsInformacoesEstaticas.metadata.section_channels.title
	// 									: cosmicjsInformacoesEstaticas.metadata.section_channels.title_ingles}
	// 							</span>
	// 						</p>
	// 						<p>
	// 							{language === 'br'
	// 								? cosmicjsInformacoesEstaticas.metadata.section_channels.subtitle
	// 								: cosmicjsInformacoesEstaticas.metadata.section_channels.subtitle_ingles}
	// 						</p>
	// 					</GridContainer>

	// 					{language && language === 'br' ? (
	// 						<Select
	// 							styles={selectStyles}
	// 							options={optionsArray}
	// 							placeholder='Selecione o perfil de usuário'
	// 							onChange={e => setSelected(e)}
	// 						/>
	// 					) : (
	// 						<Select
	// 							styles={selectStyles}
	// 							options={optionsArrayEnglish}
	// 							placeholder='Select User Profile'
	// 							onChange={e => setSelected(e)}
	// 						/>
	// 					)}

	// 					{selected && (
	// 						<>
	// 							<p
	// 								dangerouslySetInnerHTML={{
	// 									__html:
	// 										selected.label !== ''
	// 											? language === 'br'
	// 												? cosmicjsInformacoesEstaticas.metadata.section_channels.text.replace(
	// 														'#PERSONA#',
	// 														selected.label?.toUpperCase().split(':', 1)
	// 												  )
	// 												: cosmicjsInformacoesEstaticas.metadata.section_channels.text_ingles.replace(
	// 														'#PERSONA#',
	// 														selected.label?.toUpperCase().split(':', 1)
	// 												  )
	// 											: ''
	// 								}}
	// 							/>
	// 						</>
	// 					)}
	// 					<ButtonLink selected={selected} href={selected ? selected.url : false} target='_blank'>
	// 						{language === 'br' ? `Ir para formulário` : 'Go to form'}
	// 					</ButtonLink>
	// 				</AboutText>
	// 				<p
	// 					className='privacy-content'
	// 					dangerouslySetInnerHTML={{
	// 						__html:
	// 							language === 'br'
	// 								? cosmicjsInformacoesEstaticas.metadata.message_footer
	// 								: cosmicjsInformacoesEstaticas.metadata.message_footer_ingles
	// 					}}
	// 				/>
	// 			</AccordionGrid>
	// 		</AccordionsContainer>
	// 	</Layout>
	// )
}

export default PrivacidadeComponent
